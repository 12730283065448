void function(player) {
  const data = player.data
  let storeObs = null // intersection observer for avail visibility tracking

  player.trkData = {}

  // keep track of stores we've already seen through interception observer.
  // We disconnect observer and rebind all results after next requests are completed,
  // so an avail from initial request can have been tracked already and should not trigger a storeDisplayed event.
  let observedStores = []

  let availAborter = null // AbortController to cancel a running request

  // Constants for required async scripts
  const Dependencies = {
    TRACKER: 'tracker',
    SRE: 'sre',
    MAPPING: 'Mapping',
  }

  const AVAIL_SORT_ORDER = {
    PRIO_LIST: -1, // use retailer priority list
    DEFAULT: 0, // by retailer name, "alphabetically" (i.e. unicode codepoint)
    RANDOM: 1,
    PRICE: 2,
  }

  // fetch availabilities
  async function getAvails(forceLoc) {
    let url

    try {
      const { wtbid } = data
      const { product, location } = player.store
      const country = location && location.country || data.country

      if (!product) return

      document.dispatchEvent(new CustomEvent('loader-start'))

      url = `https://wtb-api-hub.swaven.com/wtb/v2/api/search/availabilities/${product.ean}?wtbid=${wtbid}&lang=${data.locale}&country=${country}`

      if (location && !forceLoc) {
        if (location.lat)
          url += `&lat=${location.lat}`
        if (location.lng)
          url += `&lng=${location.lng}`
        if (location.country)
          url += `&cc=${location.country}`
        if (location.zipCode)
          url += `&zc=${location.zipCode}`
        if (location.region)
          url += `&state=${location.region}`
      }

      if (data.draft)
        url += '&draft=1'

      if (availAborter)
        availAborter.abort() // cancel previous request

      availAborter = new AbortController()

      const res = await fetch(url, { credentials: 'include', signal: availAborter.signal })

      if (res.status != 200) {
        console.error(`avails fetch: error ${res.status}`) // eslint-disable-line no-console
        logger.error('availabilities fetch error', {
          url,
          status: res.status,
          body: await res.text(),
        })

        return
      }

      const response = await res.json()

      player.data.tiedHouseThreshold = response.tiedHouseLaw
      player.store.showRetailerText = !!response.alcoholSettings?.showRetailerText

      // APEX-27: Make additional requests if any
      const hasNext = response.next && response.next.length

      if (hasNext) {
        //  Pass the set of retailers before any merging/filtering is done
        //    so that tied-house compliance can be re-calculated with the original set.
        getOtherRetailers(
          response.next,
          availAborter.signal,
          response.tiedHouseLaw,
          response.d)
      }

      if (typeof Mapping !== 'function')
        await awaitLoad(Dependencies.MAPPING)

      // mapping json data to AWE's own model.
      // Provided object is to replace macros in clkUrl.
      const unfilteredAvails = Mapping.mapAvailabilities(response.d, {
        PAGEID: data.pageId,
        IID: data.iid,
        RFR: player.baseTrk.rfr,
        RFR2: player.baseTrk.rfr2,
        // SID: this.tracker.session && this.tracker.session.id,
        // SURL: this.tracker.session && this.tracker.session.url,
        // SRFR: this.tracker.session && this.tracker.session.referrer
      })

      if (!location || location.lat == null || !!forceLoc) {
        player.store.location = {
          lat: response.loc.Latitude,
          lng: response.loc.Longitude,
          city: response.loc.City,
          country: response.loc.Country,
          zipCode: response.loc.ZipCode,
          region: response.loc.State,
          address: response.loc.Address,
          origin: 'api',
        }
      }

      let avails = unfilteredAvails;

      // Retailer view: group avails by typology or retailer id
      if (player.data.availsView === 'retailer') {
        avails = setRetailerView(avails)

        if (player.data.availSortOrder === AVAIL_SORT_ORDER.PRIO_LIST) {
          avails.sort((a, b) => {
            return compareBy(a, b, player.data.availSortOrder, player.data.retailerPrio)
          })
        }
      }

      // Alcohol Policy: remove avails that don't match the alcohol policy
      avails = filterForTiedHouseLaw(avails)

      player.store.avails = avails

      document.dispatchEvent(new CustomEvent('availabilities-update', {
        detail: {
          avails: player.store.avails,
          fallbackStoreId: response.dsid,
        },
      }))

      player.trkData = {
        avgpc: response.avgp,
        avgdst: response.avgdst,
        nbres: response.nbres,
        nbrefret: response.nbrefret,
        avreqid: uuid(),
      }

      // reset current availability if not present if newly retrieved results.
      // if present, assign new object.
      if (player.store.currentAvail) {
        const curInNewAvails = player.store.avails
          .find(x => x.store.id === player.store.currentAvail.store.id)

        player.store.currentAvail = curInNewAvails || null
      }

      observeStores(true)

      const tm = performance.getEntriesByName(url).pop()
      const v = tm ? Math.round(tm.duration) : null

      tracker.track('apires', v ? { duration: v } : {})
      logger.log('api-avails', { duration: v })

      if (response.usids && response.usids.length > 0)
        tracker.track('unavlbsids', { sids: response.usids.slice(0, 20) })
    }
    catch (ex) {
      // ignore errors on request cancelling
      if (ex.name === 'AbortError')
        return

      logger.error('getAvails failure', ex)
    }
    finally {
      document.dispatchEvent(new CustomEvent('loader-end'))
    }
  }
  player.getAvails = getAvails


  // make additional availabilities requests for real-time retailers.
  // urls: list of urls to query
  // signal: AbortSignal to allow request cancellation.
  async function getOtherRetailers(urls, signal, tiedHouseLaw, unfilteredUnmappedOriginalAvails) {
    const proms = new Array(urls.length)

    for (let i = 0; i < urls.length; i++) {
      const url = urls[i]
      const p = new Promise(async (resolve) => { // eslint-disable-line no-async-promise-executor
        try {
          const resp = await fetch(url, {
            credentials: 'include',
            signal,
          })

          const response = await resp.json()

          if (typeof Mapping !== 'function')
            await awaitLoad(Dependencies.MAPPING)

          const avails = Mapping.mapAvailabilities(response, {
            PAGEID: data.pageId,
            IID: data.iid,
            RFR: player.baseTrk.rfr,
            RFR2: player.baseTrk.rfr2,
          })

          resolve(avails)
        }
        catch (ex) {
          if (ex.name !== 'AbortError') {
            console.error(ex) // eslint-disable-line no-console
          }
          resolve([]) // resolve anyway, or Promise.all will reject
        }
      })

      // put promises in an array to wait for globally
      proms[i] = p
    }

    // lazy-loading module while awaiting requests completion
    const [utils, allNext] = await Promise.all([
      import('./realtime.mjs'),
      Promise.all(proms),
    ])

    const unfilteredOriginalAvails = Mapping.mapAvailabilities(unfilteredUnmappedOriginalAvails, {
      PAGEID: data.pageId,
      IID: data.iid,
      RFR: player.baseTrk.rfr,
      RFR2: player.baseTrk.rfr2,
    })

    let avails = utils.mergeAvailLists(
      unfilteredOriginalAvails,
      [].concat(...allNext),
      player.data.availSortOrder,
      player.data.retailerPrio,
      player.data.availsView === 'retailer',
    )

    // Retailer view: group avails by typology or retailer id
    if (player.data.availsView === 'retailer') {
      avails = setRetailerView(avails)
    }

    // Alcohol Policy: remove avails that don't match the alcohol policy
    avails = filterForTiedHouseLaw(avails)

    player.store.avails = avails
    document.dispatchEvent(new CustomEvent('availabilities-update', {
      detail: {
        avails: player.store.avails,
      },
    }))

    utils.updateTrackingData(player.store.avails, player.trkData)
    observeStores(false)
  }

  // Retailer view: filter list to contains only one result for each retailer.
  function setRetailerView(avails) {
    const filtered = new Map()

    avails.forEach(x => {
      const typo = x.store.typologyId || x.retailer.id
      const retStore = filtered.get(typo)

      // if no store/selected store for retailer has no clkUrl, try to replace it with one that has it.
      if (!retStore || !retStore.clkUrl && x.clkUrl) {
        filtered.set(typo, x)
      }
    })

    // Map values are returned by order of insertion, even if value for a key is updated.
    // Since process avails in order, the resulting array will keep the same order.
    return Array.from(filtered.values())
  }


  // on availability click, triggers the desired behavior.
  player.redirect = (evt, avail, mode) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }

    if (avail != player.store.currentAvail)
      player.store.currentAvail = avail

    if (!mode && evt)
      mode = evt.currentTarget.dataset.redirectMode

    const openLink = window.aweParams && window.aweParams.clickThrough || window.open

    switch (mode) {
      case 'map':
        openLink(avail.store.gmapUrl)
        tracker.track('clkloc', { avail: avail, outbound: true, auto: 0 })
        break
      case 'phone':
        openLink(`tel:${avail.store.phone}`)
        tracker.track('clkloc', { avail: avail, clkloc_type: 'tel', auto: 0 })
        break
      case 'whatsapp':
        openLink(`https://api.whatsapp.com/send?phone=${avail.store.whatsapp}&text=${player.data.remoteConf.whatsapp_message}`)
        tracker.track('clkloc', { avail: avail, clkloc_type: 'whatsapp', auto: 0 })
        break
      case 'appointment':
        openLink(avail.store.appointmentUrl)
        tracker.track('clkloc', { avail, clkloc_type: 'appointment', auto: 0 })
        tracker.track('appointment', { avail })
        break
      case '2step':
        openLink(avail.voucher.secondStepUrl, '_self')
        tracker.track('2step', { avail })
        break
      case 'url':
      case 'voucher':
      default:
        const data  = { avail: avail, leadid: avail.leadid, outbound: true, auto: false } // eslint-disable-line no-case-declarations

        if (mode === 'voucher')
          data.buy_type = mode

        tracker.track('buy', data, TARGET_ALL)
        // this.trigger('product-buy', {avail: avail, leadid: avail.leadid})
        openLink(avail.clkUrl || avail.store.url || avail.retailer.url)

        // generate a new leadid
        player.resetLeadid(avail)
    }
  }

  // generate a new leadid for avail and updates the clkUrl
  player.resetLeadid = (avail) => {
    if (!avail) return

    avail.leadid =  uuid().replace(/-/g, '')
    avail.clkUrl = avail.rawClkUrl.replace('{SWN-LEADID}', avail.leadid)
  }

  player.selectProduct = (evt, idx) => {
    const pdtIdx = idx != null ? idx : evt.detail.idx

    if (pdtIdx == null || player.store.product === data.products[pdtIdx])
      return

    player.store.product = data.products[pdtIdx]
    player.trkData = {}
    getAvails()
    tracker.track('clk', { auto: evt.detail.auto, autoSel: evt.detail.auto })
  }

  // IntersectionObserver callback for storeDisplayed tracking.
  function intersectionCallback(entries, obs) {
    const ids = []

    entries.forEach(entry => {
      // an entry is sometimes included even if its ratio is below the threshold, so we check that explicitely
      if (entry.isIntersecting && entry.intersectionRatio >= obs.thresholds[0]) {
        obs.unobserve(entry.target) // no need to further observe an availability once it's been tracked
        ids.push(entry.target.dataset.sid)
        observedStores.push(entry.target.dataset.sid)
      }
    })

    if (ids.length) {
      const trk = { sids: ids }

      if (player.data.hasVouchers)
        trk.with_vouchers = player.store.avails.filter(x => ids.includes(x.store.id) && x.voucher).map(x => x.store.id).join(',')

      tracker.track('storeDisplayed', trk, TARGET_ALL)
    }
  }

  // plug observer on stores, for tracking when they become visible.
  // reset: boolean, whether to clear cache of stores already displayed.
  function observeStores(reset) {
    if (!window.IntersectionObserver) return

    if (reset)
      observedStores = []

    if (storeObs)
      storeObs.disconnect()
    else {
      storeObs = new IntersectionObserver(intersectionCallback, {
        root: null, // root node is viewport
        rootMargin: '0px',
        threshold: .5,
      })
    }

    // get all availability nodes and observe them
    const nds = document.getElementsByClassName('_avail')

    for (let i = 0; i < nds.length; i++) {
      if (!observedStores.includes(nds[i].dataset.sid))
        storeObs.observe(nds[i])
    }
  }

  // get browser geoloc, and update availabilities on success.
  function requestGeoloc() {
    navigator.geolocation.getCurrentPosition(pos => {
      if (pos && pos.coords) {
        getReverseGeocoding(pos.coords.longitude, pos.coords.latitude).then(location => {
          // accept only if country is not OOS
          if (player.data.countries.includes(location.country) )
            player.store.location = location
        })
          .catch(err => { console.error(err) }) // eslint-disable-line no-console
      }
    }, err => { console.warn(err.message) }, { timeout: 1e4 }) // eslint-disable-line no-console
  }

  // makes a browser geoloc request, with a fallback to get avails.
  function autoloc() {
    try {
      requestGeoloc()
    }
    catch (ex) {
      getAvails(true)
    }
    finally {
      // TODO: track geoloc
    }
  }
  player.autoloc = autoloc

  // returns location from lat/lng coordinates.
  async function getReverseGeocoding(lng, lat, countries = [], language = null) {
    let qs = 'access_token=pk.eyJ1Ijoic3dhdmVuIiwiYSI6ImNqbTd1dXBlcjFhejMzcHBvajVlYjFjMXcifQ.ljVSrON2CjvnSj38Nxa9RA'

    if (countries.length > 0)
      qs+= `&country=${typeof countries === 'string' ? countries : countries.join(',')}`
    if (language)
      qs+= `&language=${language}`

    try {
      const res = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?${qs}`),
        data = await res.json()

      if (res.status >= 400)
        throw new Error(data.message)

      if (data.features.length === 0)
        return null

      return parseFeature(data.features[0])
    }
    catch (ex) {
      console.error(ex) // eslint-disable-line no-console

      return null
    }
  }
  player.getReverseGeocoding = getReverseGeocoding

  function parseFeature(feat) {
    if (!feat)
      return null

    const place = {
      lng: feat.center[0],
      lat: feat.center[1],
      country: '',
      zipCode: '',
      city: '',
      address: feat.place_name,
      region: '',
    }

    if (feat.place_type.includes('postcode'))
      place.zipCode = feat.text

    if (feat.place_type.includes('country') && feat.properties.short_code)
      place.country = feat.properties.short_code.toUpperCase()

    if (feat.place_type.includes('region') && feat.properties.short_code)
      place.region = feat.properties.short_code.toUpperCase()

    if (feat.place_type.includes('place'))
      place.city = feat.text

    if (feat.context) {
      feat.context.forEach(context => {
        const type = context.id.split('.')[0]

        // Context list is ordered, country will override region for setting place.country.
        if ((type === 'country' || type ==='region' && !place.country) && !!context.short_code)
          place.country = context.short_code.toUpperCase()
        else if (type === 'postcode' && !place.zipCode)
          place.zipCode = context.text

        if ((type === 'region' || type === 'place' && !place.region) && !!context.short_code) {
          place.region = context.short_code.toUpperCase()
        }
        if (type === 'place' && !place.city)
          place.city = context.text
      })
    }

    // remove country prefix from region code.
    // Cannot be done directly when setting region, as context list is ordered by size,
    // country may not be known at that time.
    if (place.region && place.country)
      place.region = place.region.replace(new RegExp(`^${place.country}[-_\s]?`, 'i'), '') // eslint-disable-line no-useless-escape

    return place
  }
  player.parseFeature = parseFeature

  // compares 2 availabilities based on given method (e.g. price, retailer priority)
  function compareBy(availA, availB, method, retailerList) {
    if (method === AVAIL_SORT_ORDER.PRICE)
      return availA.price.amount - availB.price.amount
    else if (method === AVAIL_SORT_ORDER.RANDOM)
      return Math.random() - 0.5 // returns number in the [-0.5, +0.5[ range
    else if (method === AVAIL_SORT_ORDER.PRIO_LIST) {
      if (!retailerList)
        throw new Error('Missing retailer priority list')

      const iA = retailerList.indexOf(availA.retailer.id)
      const iB = retailerList.indexOf(availB.retailer.id)

      if (iA === -1 && iB > -1) return 1
      else if (iA > -1 && iB === -1) return -1

      return iA - iB
    }
    else {
      if (availA.retailer.name < availB.retailer.name)
        return -1
      else if (availA.retailer.name > availB.retailer.name)
        return 1
    }
  }
  player.compareBy = compareBy

  // send basic document metrics after load
  function sendLoadMetrics() {
    const perf = performance.getEntriesByType('navigation')[0]

    logger.log('load', {
      transferSize: perf && perf.transferSize,
      domLoaded: perf && Math.round(perf.duration),
      domContentLoaded: perf && Math.round(perf.domContentLoadedEventStart),
      latency: perf && Math.round(perf.responseEnd), // startTime is always 0 for a navigation entry
    })
  }

  function locationUpdated(location) {
    // no need to make new request if location update already comes from api
    if (location.origin !== 'api')
      getAvails()

    document.dispatchEvent(new CustomEvent('location-update', { detail: { location }, bubbles: true }))
  }

  function currentAvailUpdated(avail) {
    document.body.dispatchEvent(new CustomEvent('availability-select', { detail: { avail }, bubbles: true }))
  }

  // Apply Alcohol restrictions to avails, based on Tied House policy from API
  function filterForTiedHouseLaw(avails = []) {
    //  Filter only if the setting is enabled with a valid value
    if (!player.data.tiedHouseThreshold)
      return avails

    // Avails can have multiple of the same retailer, so we need to filter down to one each before counting
    const uniqueRetailers = setRetailerView(avails)
    const tiedHouseRetailerCount = uniqueRetailers
      .filter(({ retailer = {} }) => retailer.isTiedHouseLaw).length

    if (!tiedHouseRetailerCount) return avails

    const isTiedHouseCompliant = tiedHouseRetailerCount >= player.data.tiedHouseThreshold

    // Remove retailers from avails that are not in compliance with Tied House law
    return avails.filter(({ retailer = {} }) => !retailer.isTiedHouseLaw || isTiedHouseCompliant)
  }
  player.filterForTiedHouseLaw = filterForTiedHouseLaw

  // Wait for a global object to be loaded
  async function awaitLoad(objName) {
    const MAX_WAIT = 30e3 // max wait time in ms
    const BACKOFF_RATIO = 1.5 // to grow the wait interval each round
    let itv = 10 // initial wait interval in ms
    let loaded = false

    const start = performance.now()

    do {

      await new Promise(resolve => { setTimeout(resolve, itv) })

      switch (objName) {
        case Dependencies.MAPPING:
          // Mapping is a class, not a global object
          loaded = typeof Mapping === 'function'
          break
        default:
          loaded = window[objName] != null
      }
      itv *= BACKOFF_RATIO
    }
    while (!loaded && performance.now() - start < MAX_WAIT)

    const duration = performance.now() - start

    if (loaded)
      return
    else
      throw new Error(`awaitLoad timeout: ${objName} not found after ${Math.round(duration)}ms`)
  }

  /** ***********************************************************
                    Entry point
  **************************************************************/
  void async function init() {
    if (player.data.touchpoint === 'IP') {
      if (!window.tracker)
        await awaitLoad(Dependencies.TRACKER)
      tracker.track('dploy', { auto: true }, TARGET_ALL)
      tracker.track('visible')
      tracker.track('clk', { auto: true, autoSel: true })
    }

    // default watchers
    if (!window.sre)
      await awaitLoad(Dependencies.SRE)

    sre.watch({
      location: locationUpdated,
      currentAvail: currentAvailUpdated,
    })

    await sre.start(player, window.logger)

    document.body.addEventListener('mouseenter', () => {
      tracker.track('mover')
    }, { once: true, passive: true })

    if (player.data.touchpoint === 'IP')
      getAvails()

    if (!data.remoteConf.noAutoGeoloc && !data.media) {
      try {
        requestGeoloc()
      }
      catch (ex) { console.warn(ex) } // eslint-disable-line no-console
    }

    // bind 'view all' button
    const moreBtn = document.getElementById('more')

    if (moreBtn) {
      moreBtn.addEventListener('click', e => {
        const showAll = document.getElementById('avails-ctnr').classList.toggle('show-all') // eslint-disable-line no-unused-vars
        const lbl = e.currentTarget.textContent

        e.currentTarget.textContent = e.currentTarget.dataset.altLabel
        e.currentTarget.dataset.altLabel = lbl
      })
    }

    if (document.readyState === 'complete')
      sendLoadMetrics()
    else {
      // add a delay because perf duration is not set until the load handlers have run.
      // We don't use that event, so all handlers should run in less than 200ms.
      window.addEventListener('load', () => {
        setTimeout(sendLoadMetrics, 200)
      }, { once: true })
    }
  }()
}(window.player)

window.fillString = function(txt, pattern, value) {
  return txt.replace(new RegExp(`{${pattern}}`, 'g'), value)
}
